import React from "react";
import { getNewsDetail } from "services";
import { BaseLayout } from "components";
import styles from "./styles.module.scss";
import { isNative, nativeHref } from "utils";
import queryString from "query-string";
const { userType, isReaded, customerNo } = queryString.parse(
  window.location.search
);
class GuideDetail extends React.Component {
  state = {
    isKonw: false,
    data: {},
    loading: true,
  };
  componentDidMount() {
    const env = process.env.BUILD_ENV || "";
    let id;
    if (userType === "old_user") {
      document.title = "老客须知";
      id = env === "uat" ? 153728 : 247521;
    } else {
      document.title = "新手宝典";
      id = env === "uat" ? 153722 : 155475;
    }

    getNewsDetail(id)
      .then((res) => {
        const data = res.ch_msg[0];
        this.setState({
          data,
          loading: false,
        });
      })
      .catch((err) => {});
    if (isNative) nativeHref("setToolBar", { title: document.title });
  }

  // 返回
  onBack = () => {
    window.history.back();
  };

  render() {
    const { isKonw, data, loading } = this.state;
    return (
      <BaseLayout>
        <section className={styles.detail_new}>
          {isNative ? null : (
            <header className={styles.header}>
              <div className={styles.back} onClick={this.onBack} />
              <div className={styles.title}>{data.title}</div>
            </header>
          )}

          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: data.content }}
          ></div>
          {customerNo && !loading && (
            <div
              className={`${styles.iKonw} ${
                isKonw || isReaded === 1 ? styles.iKonw_k : ""
              }`}
              onClick={() => {
                this.setState({ isKonw: true });
                !(isKonw || isReaded === 1) &&
                  nativeHref("lookGuideDetail", { userType });
              }}
            >
              {isKonw || isReaded === 1 ? "已知晓" : "我已知晓"}
            </div>
          )}
        </section>
      </BaseLayout>
    );
  }
}
export default GuideDetail;
